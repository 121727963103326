import {Layout} from 'antd';
import logo from '@assets/logo/logo.png';

const {Header} = Layout;

const Navbar = () => {
  return (
    <Layout className="layout">
			<Header className="nav-wrapper">
        <img src={logo} className='logo nav-logo' alt="form"/>
			</Header>
    </Layout>
  )
}

export default Navbar