import React, { Suspense } from 'react';
import './App.css';
import Navbar from '@components/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const Home = React.lazy(() => import('./Home'));
const About = React.lazy(() => import('./About'));
const Success = React.lazy(() => import('./Success'));

const App = () => (
		<Router>
			<Suspense fallback={<div>Loading...</div>}>
				<Navbar/>
				<Routes>
					<Route path="/form" element={<Home />} />
					<Route path="/" element={<Navigate replace to="/form" />}/>
					<Route path="/about" element={<About />} />
					<Route path="/success" element={<Success />}/>
				</Routes>
			</Suspense>
		</Router>
);

export default App;
